import React, { useCallback, useEffect, useRef } from 'react';

import { FormInput } from 'components/Form/Input';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { useHistory } from 'react-router';

import { getValidationsErros } from 'utils/getValidationsErros';

import { protocoloProps } from 'utils/types';

import { ButtonBack } from 'components/ButtonBack';
import { ButtonNext } from 'components/ButtonNext';
import iconeEmail from 'assets/icons/icon-mail.svg';
import iconeTelefone from 'assets/icons/icon-phone.svg';
import iconUser from 'assets/icons/icon-user.svg';

import { ButtonGroup } from 'components/Form/ButtonGroup';
import { nextStep } from 'store/modules/dataIndexStep/action';
import { useDispatch } from 'react-redux';
import { setDataBiometria } from 'store/modules/dataBiometria/action';
import { ContentInfo } from 'components/ContentInfo';

import { useAuth } from 'hooks/auth';
import { ValidationError } from 'yup';
import { Container, TitleSection } from './styles';

import formValidator from './formValidator';

export const Biometria: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { getTotalSeconds } = useAuth();
  const history = useHistory();

  const dispatch = useDispatch();

  const protocoloPagamento = JSON.parse(
    sessionStorage.getItem('@CheckoutWeb:protocoloPagamento') ?? '',
  ) as protocoloProps;

  const enviarDadosBiometria = useCallback(
    async data => {
      const dadosBiometria = {
        clienteTelefone: data.clienteTelefone,
        clienteEmail: data.clienteEmail,
      };

      dispatch(setDataBiometria(dadosBiometria));
      dispatch(nextStep());
    },
    [dispatch],
  );

  const handleSubmit = useCallback(
    async data => {
      formRef.current?.setErrors({});
      await formValidator
        .validate(data, {
          abortEarly: false,
        })
        .then(() => {
          enviarDadosBiometria(data);
        })
        .catch(err => {
          const errors = getValidationsErros(err as ValidationError);

          formRef.current?.setErrors(errors);
        });
    },
    [enviarDadosBiometria],
  );

  useEffect(() => {
    getTotalSeconds();
  }, [getTotalSeconds]);

  return (
    <Container data-cy="biometria">
      <ContentInfo />
      <TitleSection>Informe seus dados abaixo</TitleSection>

      <Form onSubmit={handleSubmit} ref={formRef}>
        <div className="col-md">
          <FormInput
            disabled
            placeholder="Nome do cliente"
            label="clienteNome"
            icon={iconUser}
            defaultValue={protocoloPagamento.ClienteNome}
          />
        </div>

        <div className="col-md">
          <FormInput
            mask="(99) 99999-9999"
            autoComplete="none"
            placeholder="Telefone"
            label="clienteTelefone"
            icon={iconeTelefone}
          >
            *
          </FormInput>
        </div>

        <div className="col-md">
          <FormInput
            autoComplete="none"
            placeholder="E-mail"
            label="clienteEmail"
            icon={iconeEmail}
          >
            *
          </FormInput>
        </div>

        <div className="mt-5">
          <ButtonGroup>
            <ButtonBack title="VOLTAR" action={() => history.goBack()} />

            <ButtonNext title="PRÓXIMO" type="submit" />
          </ButtonGroup>
        </div>
      </Form>
    </Container>
  );
};
