import { HTMLAttributes, ReactNode } from 'react';
import { Container } from './styles';

interface ButtonGroupProps extends HTMLAttributes<HTMLElement> {
  align?: 'flex-start' | 'center' | 'flex-end';
  children: ReactNode;
}

export const ButtonGroup: React.FC<ButtonGroupProps> = ({
  children,
  align,
}) => {
  return <Container align={align}>{children}</Container>;
};
