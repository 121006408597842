import styled from 'styled-components';

export const DivRow = styled.div`
  display: flex;
  margin-top: 20px;

  .contentCvv {
    margin-left: 40px;
    margin-right: 20px;
    width: 50px;
  }

  .validateCard {
    width: 100px;
  }
`;

export const ContentValueCard = styled.div`
  display: flex;

  width: auto;

  margin-bottom: 16px;

  img {
    margin-left: auto;
    margin-right: 10px;
  }

  div {
    width: auto;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 375px;
  height: 234px;
  border-radius: 16px;
  padding: 25px;
  background: linear-gradient(97.44deg, #1e99f7 0%, #0c3bdd 100%);
  color: white;

  transition: all 0.3s;

  @media screen and (max-width: 830px) {
    display: none;
  }

  p {
    font-size: 16px;
    font-family: 'Karla', sans-serif;
    font-weight: lighter;
  }

  .textCardInfo {
    font-size: 1em;
  }
`;
