import React, { ButtonHTMLAttributes } from 'react';

import { Button } from './styles';

interface buttonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  icon?: string;
  disabled?: boolean;
}

export const ButtonNext: React.FC<buttonProps> = ({
  title,
  icon,
  disabled,
}) => {
  return (
    <Button type="submit" disabled={disabled} data-cy="buttonNext">
      {icon && <img src={icon} alt="iconButton" />}
      <p>{title}</p>
    </Button>
  );
};
