import { actionProps } from 'store/modules/types';
import { dataBiometriaProps, dataBiometriaTypes } from './types';

export function setDataBiometria(data: dataBiometriaProps): actionProps {
  return {
    type: dataBiometriaTypes.SET_DATA_BIOMETRIA,
    payload: {
      data,
    },
  };
}
