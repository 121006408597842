import styled from 'styled-components';

interface formContentProps {
  showBorder?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 550px) {
    align-items: center;
  }
`;

export const FormContent = styled.div``;

export const BorderContent = styled.div<formContentProps>``;
