import { Reducer } from 'redux';

import { userDataAddressProps, dataAddressTypes } from './types';

const INITIAL_STATE: userDataAddressProps = {
  data: {
    clienteTelefone: '',
    clienteEmail: '',
    souTitular: false,
    documentoTitularCartao: '',
    telefoneTitularCartao: '',
    emailTitularCartao: '',
    cep: '',
    cidade: '',
    logradouro: '',
    numero: '',
    bairro: '',
    complemento: '',
    uf: '',
  },
};

const dataPayment: Reducer<userDataAddressProps> = (
  state = INITIAL_STATE,
  action,
) => {
  if (action.type === dataAddressTypes.SET_USER_ADDRESS) {
    return { ...state, data: action.payload.data };
  }

  return state;
};

export default dataPayment;
