import { shade } from 'polished';
import styled from 'styled-components';

export const Button = styled.button`
  background: transparent;
  border-radius: 8px;
  border: 1px solid #076bee;

  width: 100%;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.5s;

  :hover {
    background-color: ${shade(0.01, '#f3f8ff')};
  }

  color: #076bee;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;

  p {
    margin-left: 8px;
    text-transform: uppercase;
  }
`;
