import React from 'react';

import { formaPagamentoSelecionadaProps, protocoloProps } from 'utils/types';
import { FormStepper } from 'components/FormWithSteps/FormStepper';
import { FormStep } from 'components/FormWithSteps/FormStep';

import Content from 'components/layout/Content';

import { Biometria } from 'pages/Pagamento/Biometria';

import { ConfirmarBiometria } from 'pages/Pagamento/Biometria/ConfirmarBiometria';
import { CartaoHavan } from 'pages/Pagamento/CartaoHavan';
import { BiometriaCartaoHavan } from 'pages/Pagamento/CartaoHavan/BiometriaCartaoHavan';
import { ConfirmarPagamentoCartaoHavan } from 'pages/Pagamento/CartaoHavan/ConfirmarPagamentoCartaoHavan';
import { DadosCartao } from './DadosCartao';
import { DadosTitular } from './DadosTitular';
import { RevisarPagamento } from './RevisarPagamento';
import { ConfirmarPagamento } from './ConfirmarPagamento';
import { Pix } from './Pix';

import { Container, ContentForm } from './styles';

const Pagamento: React.FC = () => {
  const protocoloPagamento = JSON.parse(
    sessionStorage.getItem('@CheckoutWeb:protocoloPagamento') ?? '',
  ) as protocoloProps;

  const selectPayment = JSON.parse(
    sessionStorage.getItem('@CheckoutWeb:formaPagamento') ?? '',
  ) as formaPagamentoSelecionadaProps;

  const biometriaNaoAprovada =
    selectPayment.TipoPagamento === 2 &&
    selectPayment.Bandeira !== 'elo' &&
    (protocoloPagamento.FormaPagamentoSelecionada === null ||
      protocoloPagamento.ChaveEventoAtual === 'ACESSODIGITAL_CRIABIOMETRIA' ||
      (protocoloPagamento.ChaveEventoAtual === 'MUNDIPAGG_AUTORIZACAO' &&
        protocoloPagamento.FormaPagamentoSelecionada.ChavePrimeiroEvento ===
          'MUNDIPAGG_AUTORIZACAO') ||
      protocoloPagamento.ChaveEventoAtual === 'PIXHAVAN_CONSULTA_COBRANCA');

  const pagamentoPix = selectPayment.TipoPagamento === 3;
  const pagamentoCartaoHavan = selectPayment.TipoPagamento === 4;

  const dadosPessoais = 'Informe os seus dados';
  const dadosCartao = 'Informe os dados do cartão';
  const confimarPagamento = 'Confirmação de pagamento';

  const progressLabels = [
    dadosCartao,
    dadosPessoais,
    'As informações estão corretas?',
    confimarPagamento,
  ];

  const progressLabelsCartaoHavan = [
    dadosCartao,
    dadosPessoais,
    confimarPagamento,
  ];

  const progressLabelsPix = [dadosCartao, 'Confirmação da Biometria'];

  return (
    <Content>
      <Container
        typePayment={selectPayment.TipoPagamento}
        className="mb-3"
        data-cy="revisarOsdados"
      >
        <ContentForm>
          {biometriaNaoAprovada ? (
            <FormStepper noForm labels={progressLabelsPix} disabledButtonBack>
              <FormStep>
                <Biometria />
              </FormStep>
              <FormStep>
                <ConfirmarBiometria />
              </FormStep>
            </FormStepper>
          ) : (
            <>
              {pagamentoPix ? (
                <Pix />
              ) : (
                <>
                  {pagamentoCartaoHavan ? (
                    <FormStepper
                      noForm
                      labels={progressLabelsCartaoHavan}
                      showBorder
                    >
                      <FormStep>
                        <BiometriaCartaoHavan />
                      </FormStep>

                      <FormStep>
                        <CartaoHavan />
                      </FormStep>

                      <FormStep>
                        <ConfirmarPagamentoCartaoHavan />
                      </FormStep>
                    </FormStepper>
                  ) : (
                    <FormStepper noForm labels={progressLabels} showBorder>
                      <FormStep>
                        <DadosCartao />
                      </FormStep>

                      <FormStep>
                        <DadosTitular />
                      </FormStep>

                      <FormStep>
                        <RevisarPagamento />
                      </FormStep>

                      <FormStep>
                        <ConfirmarPagamento />
                      </FormStep>
                    </FormStepper>
                  )}
                </>
              )}
            </>
          )}
        </ContentForm>
      </Container>
    </Content>
  );
};

export default Pagamento;
