import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;

  }



  body {
    background: #fff;
    -webkit-font-smoothing: antialised;
    background: #fff;
    color: #333;



    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {   -webkit-transition-delay: 9999s;   transition-delay: 9999s; }

  }

  body, input, button {
    font: 16px 'Nunito';
  }

  p, h1 {
    margin: 0;
    padding: 0;
    outline: 0;
  }

  button {
    cursor: pointer;
  }


`;
