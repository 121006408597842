import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FormInput } from 'components/Form/Input';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useDispatch, useSelector } from 'react-redux';

import { AplicationState } from 'store';
import { dataAddressProps } from 'store/modules/dataAddress/types';

import { useToast } from 'hooks/toast';
import { useSpinner } from 'hooks/spinner';

import { protocoloProps } from 'utils/types';

import iconeUsuario from 'assets/icons/icon-user.svg';
import iconePin from 'assets/icons/icon-pin.svg';
import iconeEmail from 'assets/icons/icon-mail.svg';
import iconeTelefone from 'assets/icons/icon-phone.svg';

import { CheckBox } from 'components/Form/CheckBox';
import { ButtonGroup } from 'components/Form/ButtonGroup';
import { ButtonBack } from 'components/ButtonBack';
import { ButtonNext } from 'components/ButtonNext';
import { backStep, nextStep } from 'store/modules/dataIndexStep/action';
import { ContentInfo } from 'components/ContentInfo';
import { setUserAddress } from 'store/modules/dataAddress/action';
import { isCnpj, isCpf } from 'validator-brazil';
import { getValidationsErros } from 'utils/getValidationsErros';

import { useAuth } from 'hooks/auth';
import { ValidationError } from 'yup';
import { Container, ContentForm, TitleSection, ContentRow } from './styles';

import validadeNoOwnerCard from './validations/formValidator';
import validadeOwnerCard from './validations/ownerCardValidation';

interface buscarEnderecoProps {
  bairro: string;
  cep: string;
  cidade: string;
  complemento?: string;
  localidade?: string;
  logradouro: string;
  uf: string;
  numero?: string;
}

const schemaBuscarEndereço = {
  bairro: '',
  cep: '',
  cidade: '',
  complemento: '',
  localidade: '',
  logradouro: '',
  uf: '',
  numero: '',
};

export const DadosTitular: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const { setLoading } = useSpinner();
  const { getTotalSeconds } = useAuth();

  const dispatch = useDispatch();

  const [titularCartao, setTitularCartao] = useState(false);

  const dataAddress = useSelector<AplicationState, dataAddressProps>(
    state => state.dataAddress.data,
  );

  const [buscarEndereco, setBuscarEndereco] =
    useState<buscarEnderecoProps>(schemaBuscarEndereço);

  const protocoloPagamento = JSON.parse(
    sessionStorage.getItem('@CheckoutWeb:protocoloPagamento') ?? '',
  ) as protocoloProps;

  const findCep = useCallback(
    async (cep: string) => {
      const newCep = cep.replace('-', '').replace(' ', '').replace('_', '');

      if (newCep.length < 8) {
        formRef.current?.setFieldValue('cidade', '');
        formRef.current?.setFieldValue('bairro', '');
        formRef.current?.setFieldValue('logradouro', '');
        formRef.current?.setFieldValue('numero', '');
      }

      if (newCep.length === 8) {
        setLoading(true);
        const data = await fetch(
          `https://viacep.com.br/ws/${newCep}/json`,
        ).then(res => res.json());

        formRef.current?.setFieldError('cep', '');
        formRef.current?.setFieldError('bairro', '');
        formRef.current?.setFieldError('logradouro', '');

        if (data.erro) {
          formRef.current?.setFieldValue('cidade', '');
          formRef.current?.setFieldValue('bairro', '');
          formRef.current?.setFieldValue('logradouro', '');
          formRef.current?.setFieldError('cep', 'CEP inválido');

          addToast({
            type: 'danger',
            title: 'Atenção',
            description: 'CEP inválido.',
          });
          setLoading(false);
          return;
        }

        formRef.current?.setFieldValue(
          'cidade',
          `${data.localidade} - ${data.uf}`,
        );
        formRef.current?.setFieldValue('bairro', data.bairro);
        formRef.current?.setFieldValue('logradouro', data.logradouro);

        formRef.current?.getFieldRef('numero').current.focus();
        setBuscarEndereco(data);
        setLoading(false);
      } else {
        setBuscarEndereco(schemaBuscarEndereço);
      }
    },
    [addToast, setLoading],
  );

  const handleOwnerCard = useCallback(
    data => {
      const formatState = data.cidade.split(' - ');

      const novoEndereco = {
        ...data,
        souTitular: true,
        cidade: formatState[0],
        uf: formatState[1],
      };

      dispatch(setUserAddress(novoEndereco));
      dispatch(nextStep());
    },
    [dispatch],
  );

  const handleOutherOwner = useCallback(
    data => {
      if (data.documentoTitularCartao) {
        const verify = data.documentoTitularCartao.replace(/[^0-9]+/g, '');
        if (verify.length > 11) {
          if (!isCnpj(verify)) {
            addToast({
              type: 'danger',
              title: 'Atenção',
              description: 'CNPJ inválido.',
            });

            formRef.current?.setFieldError(
              'documentoTitularCartao',
              'CNPJ inválido.',
            );
            return;
          }
        } else if (!isCpf(verify)) {
          addToast({
            type: 'danger',
            title: 'Atenção',
            description: 'CPF inválido.',
          });
          formRef.current?.setFieldError(
            'documentoTitularCartao',
            'CPF inválido.',
          );
          return;
        }
      }

      const formatState = data.cidade.split(' - ');

      const novoEndereco = {
        ...data,
        souTitular: false,
        cidade: formatState[0],
        uf: formatState[1],
      };

      dispatch(setUserAddress(novoEndereco));
      dispatch(nextStep());
    },
    [addToast, dispatch],
  );

  const handleSubmit = useCallback(
    async (data: dataAddressProps) => {
      formRef.current?.setErrors({});

      try {
        if (data.souTitular) {
          await validadeOwnerCard.validate(data, {
            abortEarly: false,
          });

          handleOwnerCard(data);
        } else {
          await validadeNoOwnerCard.validate(data, {
            abortEarly: false,
          });

          handleOutherOwner(data);
        }
      } catch (err) {
        const errors = getValidationsErros(err as ValidationError);

        formRef.current?.setErrors(errors);
      }
    },
    [handleOutherOwner, handleOwnerCard],
  );

  useEffect(() => {
    getTotalSeconds();
  }, [getTotalSeconds]);

  useEffect(() => {
    setTitularCartao(dataAddress.souTitular);
    setBuscarEndereco(dataAddress);

    formRef.current?.setFieldValue('souTitular', dataAddress.souTitular);

    if (dataAddress.uf) {
      formRef.current?.setFieldValue(
        'cidade',
        `${dataAddress.cidade} - ${dataAddress.uf}`,
      );
    }
  }, [dataAddress, dataAddress.souTitular, findCep]);

  return (
    <Container data-cy="dadosDoTitular">
      <ContentInfo />
      <ContentForm>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <TitleSection>
            <b>Informe seus dados abaixo</b>
          </TitleSection>

          <div className="col-md">
            <FormInput
              icon={iconeUsuario}
              type="text"
              label="nomecliente"
              autoComplete="none"
              disabled
              value={protocoloPagamento.ClienteNome}
            />
          </div>

          <div className="col-md">
            <FormInput
              icon={iconeTelefone}
              mask="(99) 99999-9999"
              placeholder="Celular"
              label="clienteTelefone"
              autoComplete="none"
              defaultValue={dataAddress.clienteTelefone}
            >
              *
            </FormInput>
          </div>

          <div className="col-md">
            <FormInput
              icon={iconeEmail}
              placeholder="E-mail"
              label="clienteEmail"
              autoComplete="none"
              defaultValue={dataAddress.clienteEmail}
            >
              *
            </FormInput>
          </div>

          <div className="mt-4">
            <CheckBox
              label="Sou Titular do cartão"
              name="souTitular"
              onChange={() => {
                setTitularCartao(!titularCartao);
              }}
            />
          </div>

          {!titularCartao && (
            <div>
              <TitleSection>
                <b>Dados do titular do cartão</b>
              </TitleSection>

              <ContentRow>
                <div className="col-md">
                  <FormInput
                    icon={iconeUsuario}
                    cpfCnpj
                    placeholder="CPF ou CNPJ"
                    label="documentoTitularCartao"
                    defaultValue={dataAddress.documentoTitularCartao}
                    maxLength={18}
                    autoComplete="none"
                    formRef={formRef}
                  >
                    *
                  </FormInput>
                </div>
                <div className="col-md">
                  <FormInput
                    mask="(99) 99999-9999"
                    icon={iconeTelefone}
                    placeholder="Celular"
                    label="telefoneTitularCartao"
                    defaultValue={dataAddress.telefoneTitularCartao}
                    autoComplete="none"
                  >
                    *
                  </FormInput>
                </div>
              </ContentRow>

              <div className="col-md">
                <FormInput
                  icon={iconeEmail}
                  placeholder="E-mail"
                  label="emailTitularCartao"
                  defaultValue={dataAddress.emailTitularCartao}
                  autoComplete="none"
                >
                  *
                </FormInput>
              </div>
            </div>
          )}

          <div className="mb-3">
            <TitleSection>
              <b>Endereço do titular do cartão</b>
            </TitleSection>

            <ContentRow>
              <div className="col">
                <FormInput
                  mask="99999-999"
                  placeholder="CEP"
                  autoComplete="none"
                  label="cep"
                  defaultValue={dataAddress.cep}
                  onChange={value => findCep(value.target.value)}
                >
                  *
                </FormInput>
              </div>

              <div className="col">
                <FormInput
                  disabled
                  placeholder="Cidade"
                  label="cidade"
                  type="text"
                  defaultValue={
                    buscarEndereco.cidade || buscarEndereco.localidade
                  }
                />
              </div>
            </ContentRow>

            <FormInput
              icon={iconePin}
              placeholder="Endereço"
              label="logradouro"
              autoComplete="none"
              type="text"
              defaultValue={buscarEndereco.logradouro}
            >
              *
            </FormInput>

            <ContentRow>
              <div className="col">
                <FormInput
                  placeholder="Bairro"
                  label="bairro"
                  type="text"
                  defaultValue={buscarEndereco.bairro}
                >
                  *
                </FormInput>
              </div>
              <div className="col">
                <FormInput
                  placeholder="Número"
                  label="numero"
                  autoComplete="none"
                  type="number"
                  defaultValue={buscarEndereco.numero}
                >
                  *
                </FormInput>
              </div>
            </ContentRow>

            <FormInput
              placeholder="Complemento"
              label="complemento"
              type="text"
              defaultValue={buscarEndereco.complemento}
            />
          </div>

          <ButtonGroup>
            <ButtonBack
              title="VOLTAR"
              type="button"
              action={() => {
                dispatch(backStep());
              }}
            />

            <ButtonNext title="PRÓXIMO" type="submit" />
          </ButtonGroup>
        </Form>
      </ContentForm>
    </Container>
  );
};
