import { object, string } from 'yup';

const BIRTH_DATE_INVALID = 'Informe uma Data de Nascimento válida';

export default object().shape({
  cpfCliente: string().min(14, 'Mínimo 11 caracteres'),
  dataNascimento: string()
    .test('DataNascimento', BIRTH_DATE_INVALID, value => {
      if (!value) return false;

      const anoDigitado = value.split('/');
      const anoAtual = new Date().getFullYear();
      const verificar = anoAtual - Number(anoDigitado[2]);

      if (Number(anoDigitado[1]) > 12) return false;
      if (Number(anoDigitado[0]) > 31) return false;

      return verificar > 18 && verificar < 150;
    })
    .min(10, 'Mínimo 8 caracteres'),
  senha: string().required('Campo obrigatório'),
});
