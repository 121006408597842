import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 24px;
  }
`;
