import { createStore, Store } from 'redux';

import { userDataPaymentProps } from './modules/dataPayment/types';
import { userDataAddressProps } from './modules/dataAddress/types';
import { userDataIndexProps } from './modules/dataIndexStep/types';
import { userDataBiometriaProps } from './modules/dataBiometria/types';

import rootReducer from './modules/rootReducer';

export interface AplicationState {
  dataPayment: userDataPaymentProps;
  dataAddress: userDataAddressProps;
  dataIndexStep: userDataIndexProps;
  dataBiometria: userDataBiometriaProps;
}

const store: Store<AplicationState> = createStore(rootReducer);

export default store;
