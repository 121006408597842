import { shade } from 'polished';
import styled from 'styled-components';

interface tipoPagamento {
  name: string;
}

export const Container = styled.div`
  height: calc(100vh - 100px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 530px) {
    height: 100vh;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  #contentNovo {
    background: #076bee;
    width: 46px;
    height: 24px;
    color: #eee;
    padding: 5px;
    border-radius: 3px;

    font-weight: 500;
    font-size: 10px;
    line-height: 16px;

    justify-content: center;
    display: flex;

    @media screen and (max-width: 400px) {
      font-size: 14px;
      width: 50px;
    }
  }

  #contentInfo {
    margin-left: 10px;
    width: 100%;
    margin-right: 0px;
    font-family: 'Nunito';
    font-weight: 700;
    font-size: 16px;
  }
`;

export const Button = styled.button<tipoPagamento>`
  height: 56px;
  width: 376px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #fff;
  font-size: 20px;
  padding: 0 20px;

  border: ${props => (props.name === 'Pix' ? '1px solid #184fa1' : 0)};

  :hover {
    background-color: ${shade(0.05, '#fff')};
  }

  @media screen and (max-width: 400px) {
    font-size: 15px;
    font-weight: bold;
    padding: 0 10px;
    width: 320px;
  }

  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 25px;
      height: 25px;
    }
  }

  & + button {
    margin-top: 10px;
  }
`;

export const TitleSection = styled.p`
  font-weight: bold;
  font-size: 24px;
  font-family: 'Nunito';
  color: #06357a;
  margin-bottom: 5px;
  text-align: center;
  margin: 20px 0 24px;
  width: 100%;
  max-width: 600px;
  transition: all 0.2s;

  @media screen and (max-width: 1060px) {
    font-size: 18px;
  }
`;

export const ContentMobile = styled.div`
  display: none;
  background-color: #076bee;
  width: 100%;
  height: 104px;
  margin-top: auto;
  padding: 20px;

  @media screen and (max-width: 530px) {
    display: flex;
    justify-content: center;
  }
`;

export const ContentWeb = styled.div`
  display: flex;
  padding: 20px;

  @media screen and (max-width: 530px) {
    display: none;
  }
`;

export const ContentToast = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid #e8f1ff;
  color: #334156;
  background: #e8f1ff;
  width: 390px;
  border-radius: 8px;

  @media screen and (max-width: 530px) {
    background: #076bee;
    color: #fff;
    width: 100%;
  }

  .alignStart {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  padding: 20px;
`;

export const TextSmall = styled.p`
  font-size: 0.7rem;
  font-family: Nunito;
`;

export const TextBold = styled.p`
  font-size: 0.9rem;
  font-weight: bold;
  font-family: Nunito;
`;
