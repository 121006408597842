import { Reducer } from 'redux';

import { userDataPaymentProps, dataPaymentTypes } from './types';

const INITIAL_STATE: userDataPaymentProps = {
  data: {
    numeroCartao: '',
    mesVencimento: '',
    anoVencimento: '',
    validade: '',
    nomeCartao: '',
    cvv: '',
    formaPagamento: 0,
    bandeira: '',
  },
};

const dataPayment: Reducer<userDataPaymentProps> = (
  state = INITIAL_STATE,
  action,
) => {
  if (action.type === dataPaymentTypes.SET_USER) {
    return { ...state, data: action.payload.data };
  }

  return state;
};

export default dataPayment;
