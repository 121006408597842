import React from 'react';

import notFoundIcon from 'assets/img/notFound.png';
import { Container } from './styles';

export const PageNotFound: React.FC = () => {
  return (
    <Container>
      <img src={notFoundIcon} alt="iconNotFound" />
      <p>Algo deu errado, tente novamente.</p>
    </Container>
  );
};
