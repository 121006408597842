import React, { createContext, useContext, useState } from 'react';

type TabContextData = {
  setActiveTab(i: number): void;
  activeTab: number;
};

const TabContext = createContext<TabContextData>({} as TabContextData);

const TabProvider: React.FC = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <TabContext.Provider value={{ setActiveTab, activeTab }}>
      {children}
    </TabContext.Provider>
  );
};

function useTab(): TabContextData {
  const context = useContext(TabContext);

  if (!context) {
    throw new Error('useTab must be used within an TabProvider');
  }

  return context;
}

export { TabProvider, useTab };
