import visaLogo from 'assets/icons/bandeiras/visa.svg';
import masterLogo from 'assets/icons/bandeiras/mastercard.svg';
import hiperLogo from 'assets/icons/bandeiras/hiper.svg';
import amexLogo from 'assets/icons/bandeiras/amex.svg';
import maestroLogo from 'assets/icons/bandeiras/maestro.svg';
import eloLogo from 'assets/icons/bandeiras/elo.svg';
import hipercardLogo from 'assets/icons/bandeiras/hipercard.svg';
import dinersLogo from 'assets/icons/bandeiras/diners.svg';
import discoverLogo from 'assets/icons/bandeiras/discover.svg';

export default function getLogoPayment(bandeira: string): string {
  let iconSelected = '';

  switch (bandeira) {
    case 'Visa':
      iconSelected = visaLogo;
      break;

    case 'Mastercard':
      iconSelected = masterLogo;
      break;

    case 'Hiper':
      iconSelected = hiperLogo;
      break;

    case 'Hipercard':
      iconSelected = hipercardLogo;
      break;

    case 'American Express':
      iconSelected = amexLogo;
      break;

    case 'Diners Club':
      iconSelected = dinersLogo;
      break;

    case 'Discover':
      iconSelected = discoverLogo;
      break;

    case 'Maestro':
      iconSelected = maestroLogo;
      break;

    case 'Elo':
      iconSelected = eloLogo;
      break;

    default:
      iconSelected = '';
      break;
  }
  return iconSelected;
}
