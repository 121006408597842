import Loader from 'react-loader-spinner';
import colors from 'styles/colors';
import { Container } from './styles';

interface toastProps {
  title: string;
  textInfo: string;
  type: string;
}

export const Toast: React.FC<toastProps> = ({ title, textInfo, type }) => {
  return (
    <Container type={type}>
      <div className="d-flex">
        {type === 'loading' && (
          <div className="me-2">
            <Loader type="Oval" color={colors.light} height={20} width={20} />
          </div>
        )}

        <p id="textTitle">{title}</p>
      </div>

      <p id="textInfo">{textInfo}</p>
    </Container>
  );
};
