import { actionProps } from 'store/modules/types';
import { dataPaymentProps } from './types';

export function setUserPay(data: dataPaymentProps): actionProps {
  return {
    type: '@user/SET_USER_PAY',
    payload: {
      data,
    },
  };
}
