import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

type ModalContextData = {
  isOpen: boolean;
  Close(): void;
  showModal(data: ModalContentData): void;
  modalContent: ModalContentData;
};

type ModalContentData = {
  title?: string;
  content: ReactNode;
  footer?: string;
  width?: 'modal-sm' | 'modal-lg' | 'modal-xl';
};

const ModalContext = createContext<ModalContextData>({} as ModalContextData);

const ModalProvider: React.FC = ({ children }) => {
  const [close, setClose] = useState(true);
  const [modalContent, setModalContent] = useState<ModalContentData>(
    {} as ModalContentData,
  );

  const isOpen = useMemo(() => {
    return !close;
  }, [close]);

  const Close = useCallback(() => {
    setClose(true);
  }, []);

  const showModal = useCallback(data => {
    setModalContent(data);
    setClose(false);
  }, []);

  return (
    <ModalContext.Provider value={{ isOpen, Close, showModal, modalContent }}>
      {children}
    </ModalContext.Provider>
  );
};

function useModal(): ModalContextData {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within an ModalProvider');
  }

  return context;
}

export { ModalProvider, useModal };
