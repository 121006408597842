import React, { useCallback } from 'react';

import { Toast } from 'components/Toast';

import { Container } from './styles';

export const Confirmacao: React.FC = () => {
  const handleNextStep = useCallback(() => {
    window.location.href = `${process.env.REACT_APP_URL_PORTAL_HAVAN}`;
  }, []);

  return (
    <Container>
      <div>
        <Toast
          title="Recebemos seu pagamento"
          textInfo="Seu pagamento foi realizado com sucesso"
          type="success"
        />
        <button onClick={handleNextStep} type="button">
          FECHAR ESTA ABA
        </button>
      </div>
    </Container>
  );
};
