import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import api from 'services/api';
import { RefreshAlert } from 'components/RefreshAlert';
import { protocoloProps } from 'utils/types';

interface AuthContextData {
  token: string;
  setDateLimit(DataHoraLimite: string): void;
  getTotalSeconds(): void;
  setToken(token: string): void;
  time: {
    minutes: number;
    seconds: number;
  };
}

interface AuthState {
  token: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = sessionStorage.getItem('@CheckoutWeb:token');

    if (token) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return {
        token,
      };
    }

    return {} as AuthState;
  });

  const [time, setTime] = useState({
    minutes: 0,
    seconds: 59,
  });

  const formartTimeLeft = useCallback((totalSeconds: number) => {
    if (totalSeconds > 0) {
      let timeLeft = {
        minutes: 0,
        seconds: 0,
      };

      const formartMinutes = totalSeconds / 60;
      const formartSeconds = totalSeconds % 60;

      timeLeft = {
        minutes: Math.floor(formartMinutes),
        seconds: Math.floor(formartSeconds),
      };

      return timeLeft;
    }
    return { minutes: 0, seconds: 0 };
  }, []);

  const setToken = useCallback((token: string) => {
    sessionStorage.setItem('@CheckoutWeb:token', token);

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token });
  }, []);

  const setDateLimit = useCallback(
    (totalSeconds: string) => {
      localStorage.setItem('@CheckoutWeb:totalSeconds', totalSeconds);
      setTime(formartTimeLeft(Number(totalSeconds)));
    },
    [formartTimeLeft],
  );

  const countdown = useCallback(() => {
    if (time.seconds > 0) {
      setTime({
        minutes: time.minutes,
        seconds: (time.seconds -= 1),
      });
    }

    if (time.minutes >= 1 && time.seconds === 0) {
      setTime({
        minutes: (time.minutes -= 1),
        seconds: (time.seconds = 59),
      });
    }
  }, [time]);

  const getTotalSeconds = useCallback(async () => {
    const protocoloPagamento = JSON.parse(
      sessionStorage.getItem('@CheckoutWeb:protocoloPagamento') ?? '',
    ) as protocoloProps;

    await api
      .get(`Protocolo/${protocoloPagamento.GuidProtocolo}/situacao`)
      .then(result => {
        const { Data } = result.data;
        setDateLimit(Data.TempoLimiteSegundos);
      });
  }, [setDateLimit]);

  useEffect(() => {
    const timer = setTimeout(() => {
      countdown();
    }, 1000);

    return () => clearTimeout(timer);
  }, [countdown]);

  return (
    <AuthContext.Provider
      value={{
        token: data.token,
        setToken,
        setDateLimit,
        time,
        getTotalSeconds,
      }}
    >
      <RefreshAlert />
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
