// eslint-disable-next-line no-shadow
export enum dataPaymentTypes {
  SET_USER = '@user/SET_USER_PAY',
}

export interface dataPaymentProps {
  numeroCartao: string;
  mesVencimento: string;
  anoVencimento: string;
  nomeCartao: string;
  cvv: string;
  formaPagamento: number;
  bandeira: string;
  validade: string;
}

export const schemaPaymentProps = {
  numeroCartao: '',
  mesVencimento: '',
  anoVencimento: '',
  nomeCartao: '',
  cvv: '',
  formaPagamento: 0,
  bandeira: '',
  validade: '',
};

export interface userDataPaymentProps {
  data: dataPaymentProps;
}
