import { object, string } from 'yup';

export default object().shape({
  numeroCartao: string()
    .min(17, 'Mínimo 14 caracteres')
    .max(20, 'Maximo 16 caracteres'),
  cvv: string()
    .matches(/^[0-9]+$/, 'Apenas números')
    .min(3, 'Mínimo 3 caracteres'),
  nomeCartao: string().required('Campo Obrigatório'),
  validade: string()
    .required('Campo Obrigatório')
    .test('DataNascimento', 'Data inválida', value => {
      if (!value) return false;

      const validade = value.split('/', 2);

      const anoAtual = new Date().getFullYear();
      const mesAtual = new Date().getMonth() + 1;

      const mesDigitao = parseInt(validade[0], 10);
      const anoDigitado = parseInt(validade[1], 10);

      if (anoDigitado > anoAtual) return true;

      return mesDigitao > mesAtual;
    })
    .min(7, 'Mínimo 6 caracteres'),
});
