import { useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { Container } from './styles';

export const RefreshAlert: React.FC = () => {
  const { addToast } = useToast();
  const { time } = useAuth();

  const [isShow, setIsShow] = useState(false);

  const timeFormated = useMemo(
    () => ({
      minutes: `00${time.minutes}`.slice(-2),
      seconds: `00${time.seconds}`.slice(-2),
    }),
    [time],
  );

  useEffect(() => {
    if (time.minutes <= 0 && time.seconds < 1) {
      addToast({
        type: 'danger',
        title: 'Sua sessão expirou',
        description: `Sessão expirada.`,
      });

      const timer = setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_URL_PORTAL_HAVAN}`;
      }, 3000);

      return () => clearTimeout(timer);
    }

    if (time.minutes < 2) {
      setIsShow(true);
      if (!time.seconds) {
        addToast({
          type: 'info',
          title: 'Sua sessão expirará em breve',
          description: `Faltam menos de ${time.minutes} minutos para sua sessão expirar.`,
        });
      }

      return;
    }

    if (time.minutes >= 10 && time.minutes < 11) {
      setIsShow(true);
      if (!time.seconds) {
        addToast({
          type: 'info',
          title: 'Sua sessão expirará em breve',
          description: `Faltam menos de ${time.minutes} minutos para sua sessão expirar.`,
        });
      }

      return;
    }
    setIsShow(false);
  }, [addToast, time]);

  return (
    <>
      {isShow && (
        <Container className="btn btn-primary" type="button">
          {time.minutes || time.seconds > 0 ? (
            <>
              <p>
                Sessão expirando...
                <br />
                {timeFormated.minutes}:{timeFormated.seconds} <br />
              </p>
            </>
          ) : (
            'Sessão expirada.'
          )}
        </Container>
      )}
    </>
  );
};
