import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import api from 'services/api';
import { AplicationState } from 'store';
import { dataBiometriaProps } from 'store/modules/dataBiometria/types';
import { backStep } from 'store/modules/dataIndexStep/action';

import { protocoloProps } from 'utils/types';
import { Toast } from 'components/Toast';

import { ButtonBack } from 'components/ButtonBack';
import { Container } from './styles';

export const ConfirmarPagamentoCartaoHavan: React.FC = () => {
  const dataBiometria = useSelector<AplicationState, dataBiometriaProps>(
    state => state.dataBiometria.data,
  );

  const protocoloPagamento = JSON.parse(
    sessionStorage.getItem('@CheckoutWeb:protocoloPagamento') ?? '',
  ) as protocoloProps;

  const formPayment = JSON.parse(
    sessionStorage.getItem('@CheckoutWeb:formaPagamento') ?? '',
  );

  const messageSucess =
    'Para sua segurança enviaremos um SMS no número de celular e um e-mail para que a verificação de identidade seja realizada. Após a verificação de identidade você poderá prosseguir com o pagamento.';

  const messageLoading =
    'Aguarde um instante enquanto processamos o pagamento.';

  const [statusFinal, setStatusFinal] = useState('loading');
  const [textInfo, setTextInfo] = useState(messageLoading);
  const [titleToast, setTitleToast] = useState('Processando o pagamento');

  const dispatch = useDispatch();

  const finalizarPagamento = useCallback(async () => {
    const dadosPagamento = {
      GuidProtocolo: protocoloPagamento.GuidProtocolo,
      IdFormaPagamento: formPayment.Id,
      ChaveEvento: formPayment.ChavePrimeiroEvento,
      Transacoes: [
        {
          ValorLiquido: protocoloPagamento.ValorLiquido,
          ValorParcela: protocoloPagamento.ValorParcela,
          CartaoHavan: {
            Documento: dataBiometria.cpfCliente,
            DataNascimento: dataBiometria.dataNascimento,
            Senha: dataBiometria.senha,
            QtdeParcelas: 0,
            EmailTitularCartao: dataBiometria.clienteEmail,
            TelefoneTitularCartao: dataBiometria.clienteTelefone,
          },
        },
      ],
    };

    await api
      .post('Protocolo/confirmar-evento', dadosPagamento)
      .then(() => {
        setTitleToast('Atenção');
        setTextInfo(messageSucess);
        setStatusFinal('success');
      })
      .catch(error => {
        const { Messages } = error.response.data;
        setTextInfo(Messages || error.message);
        setStatusFinal('danger');
        setTitleToast('Atenção');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    finalizarPagamento();
  }, [finalizarPagamento]);

  return (
    <Container data-cy="confirmarPagamento">
      <Toast title={titleToast} textInfo={textInfo} type={statusFinal} />
      {statusFinal === 'danger' && (
        <div className="contentButton">
          <ButtonBack title="voltar" action={() => dispatch(backStep())} />
        </div>
      )}
      {statusFinal === 'success' && (
        <div className="contentButton">
          <ButtonBack
            title="fechar aba"
            action={() => {
              window.location.href = `${process.env.REACT_APP_URL_PORTAL_HAVAN}`;
            }}
          />
        </div>
      )}
    </Container>
  );
};
