import React, { ButtonHTMLAttributes } from 'react';

import { Button } from './styles';

interface buttonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  action(): void;
  icon?: string;
}

export const ButtonBack: React.FC<buttonProps> = ({ title, icon, action }) => {
  return (
    <Button type="button" onClick={action} data-cy="buttonBack">
      {icon && <img src={icon} alt="iconButton" />}
      <p>{title}</p>
    </Button>
  );
};
