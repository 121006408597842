import styled from 'styled-components';

export const Container = styled.div`
  background: #f3f8ff;
  border-radius: 8px;
  width: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px;

  div {
    margin-bottom: 3px;

    button {
      border: 0;
      background-color: transparent;

      svg {
        width: 25px;
        height: 25px;
        color: #334156;
        opacity: 0.4;
      }
    }
  }
`;
