import React, { ReactNode } from 'react';

export type FormStepProps = {
  children: ReactNode;
  handleSubmit?(data: {}): Promise<void>;
};

export const FormStep: React.FC<FormStepProps> = ({ children }) => {
  return <> {children} </>;
};
