import styled from 'styled-components';

interface containerProps {
  typePayment: number;
}

export const Container = styled.div<containerProps>`
  width: 100vw;
  max-width: 580px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.2s;

  form {
    width: 100%;
  }

  @media screen and (max-width: 530px) {
    box-shadow: none;
    margin-top: -20px;
    width: 100%;
  }

  #contentTitlePayment {
    display: flex;
    align-items: center;

    font-weight: bold;
    font-size: 18px;
    color: #06357a;
  }
`;
