import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Pagamento from 'pages/Pagamento';
import FormasPagamento from 'pages/FormasPagamento';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={FormasPagamento} />
    <Route path="/pagamento" exact component={Pagamento} />
  </Switch>
);

export default Routes;
