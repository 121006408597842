import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { FormHandles } from '@unform/core';

import { FormInput } from 'components/Form/Input';
import { formaPagamentoSelecionadaProps } from 'utils/types';
import { useToast } from 'hooks/toast';

import iconeCalendario from 'assets/icons/icon-calendario.svg';
import iconeCadeado from 'assets/icons/icon-lock.svg';
import iconUser from 'assets/icons/icon-user.svg';
import { isCpf } from 'validator-brazil';
import { ButtonBack } from 'components/ButtonBack';
import { ButtonNext } from 'components/ButtonNext';
import { Form } from '@unform/web';

import { ButtonGroup } from 'components/Form/ButtonGroup';
import { ToastValues } from 'components/ToastValues';
import { useAuth } from 'hooks/auth';

import { useDispatch, useSelector } from 'react-redux';
import { backStep, nextStep } from 'store/modules/dataIndexStep/action';

import { setDataBiometria } from 'store/modules/dataBiometria/action';
import { AplicationState } from 'store';
import { dataBiometriaProps } from 'store/modules/dataBiometria/types';
import formValidator from 'pages/Pagamento/CartaoHavan/formValidator';
import { getValidationsErros } from 'utils/getValidationsErros';
import { Container } from './styles';

export const CartaoHavan: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const { getTotalSeconds } = useAuth();

  const selectPayment = JSON.parse(
    sessionStorage.getItem('@CheckoutWeb:formaPagamento') ?? '',
  ) as formaPagamentoSelecionadaProps;

  const dataBiometria = useSelector<AplicationState, dataBiometriaProps>(
    state => state.dataBiometria.data,
  );

  const dispatch = useDispatch();

  const cpfCliente = formRef.current?.getFieldValue('cpfCliente');
  const dataNascimento = formRef.current?.getFieldValue('dataNascimento');
  const senha = formRef.current?.getFieldValue('senha');

  const verificarDados = useMemo(
    () => !(cpfCliente && dataNascimento && senha),
    [dataNascimento, cpfCliente, senha],
  );

  const salvarDados = useCallback(
    data => {
      const verify = data.cpfCliente.replace(/[^0-9]+/g, '');

      if (!isCpf(verify)) {
        addToast({
          type: 'danger',
          title: 'Atenção',
          description: 'CPF inválido.',
        });
        formRef.current?.setFieldError('cpfCliente', 'CPF inválido.');
        return;
      }

      const dadosBiometria = {
        ...dataBiometria,
        cpfCliente: data.cpfCliente,
        dataNascimento: data.dataNascimento,
        senha: data.senha,
      };

      dispatch(setDataBiometria(dadosBiometria));

      dispatch(nextStep());
    },
    [addToast, dataBiometria, dispatch],
  );

  const handleSubmit = useCallback(
    async data => {
      formRef.current?.setErrors({});
      await formValidator
        .validate(data, {
          abortEarly: false,
        })
        .then(() => {
          salvarDados(data);
        })
        .catch(err => {
          const errors = getValidationsErros(err);

          formRef.current?.setErrors(errors);
        });
    },
    [salvarDados],
  );

  useEffect(() => {
    getTotalSeconds();
  }, [dataBiometria, getTotalSeconds]);

  return (
    <Container typePayment={selectPayment.TipoPagamento} data-cy="cartaoHavan">
      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className="mt-3 mb-3" id="contentTitlePayment">
          <p id="titleMethodPayment">Informe os dados do cartão</p>
        </div>
        <FormInput
          width="input-group input-group-lg"
          cpfCnpj
          maxLength={14}
          icon={iconUser}
          placeholder="CPF do titular do cartão"
          label="cpfCliente"
          defaultValue={dataBiometria.cpfCliente}
        >
          *
        </FormInput>

        <FormInput
          icon={iconeCalendario}
          placeholder="Data de nascimento"
          label="dataNascimento"
          mask="99/99/9999"
          defaultValue={dataBiometria.dataNascimento}
        >
          *
        </FormInput>

        <FormInput
          width="input-group input-group-lg"
          icon={iconeCadeado}
          type="text"
          label="senha"
          placeholder="Senha do cartão"
          defaultValue={dataBiometria.senha}
        >
          *
        </FormInput>

        <div className="mt-3">
          <ToastValues payment={selectPayment} />
        </div>

        <div className="mt-4">
          <ButtonGroup>
            <ButtonBack title="VOLTAR" action={() => dispatch(backStep())} />

            <ButtonNext
              title="PRÓXIMO"
              type="submit"
              disabled={verificarDados}
            />
          </ButtonGroup>
        </div>
      </Form>
    </Container>
  );
};
