import React, { useCallback } from 'react';
import { formatMoney } from 'utils/numberFormat';
import { formaPagamentoSelecionadaProps, protocoloProps } from 'utils/types';

import { Container, TextBold, TextSmall } from './styles';

interface toastValuesProps {
  payment: formaPagamentoSelecionadaProps;
}

export const ToastValues: React.FC<toastValuesProps> = ({ payment }) => {
  const protocoloPagamento = JSON.parse(
    sessionStorage.getItem('@CheckoutWeb:protocoloPagamento') ?? '',
  ) as protocoloProps;

  const typePaymentTitle = useCallback(() => {
    let textButton = '';

    if (payment.TipoPagamento === 1) {
      textButton = 'Cartão de Crédito';
    }

    if (payment.TipoPagamento === 2 && payment.Bandeira === 'elo') {
      textButton = 'Cartão de Débito Virtual Caixa';
    }

    if (payment.TipoPagamento === 2 && payment.Bandeira.length === 0) {
      textButton = 'Cartão de Débito C6 Bank';
    }

    if (payment.TipoPagamento === 3) {
      textButton = 'Pix';
    }

    if (payment.TipoPagamento === 4) {
      textButton = 'Cartão Havan';
    }

    return textButton;
  }, [payment.Bandeira, payment.TipoPagamento]);

  return (
    <Container>
      <div className="alignStart">
        <TextSmall>Forma de pagamento</TextSmall>
        <TextBold>{typePaymentTitle()}</TextBold>
      </div>

      {payment.TipoPagamento === 1 && (
        <div>
          <TextSmall>Parcelamento</TextSmall>
          <TextBold>
            {protocoloPagamento.Parcelas.ConfigParcelas[0].Vezes}x{' '}
            {protocoloPagamento.Parcelas.ConfigParcelas[0].Juros === 0
              ? 'sem juros'
              : 'com juros'}
          </TextBold>
        </div>
      )}
      <div>
        <TextSmall>Valor total</TextSmall>
        <TextBold>
          <b>{formatMoney(protocoloPagamento.ValorLiquido)}</b>
        </TextBold>
      </div>
    </Container>
  );
};
