import { object, string } from 'yup';

const campoObrigatorio = 'Campo obrigatório';

export default object().shape({
  clienteTelefone: string().min(15, 'Mínimo 11 caracteres'),
  cep: string().min(8, 'Mínimo 8 caracteres'),
  logradouro: string().required(campoObrigatorio),
  numero: string().required(campoObrigatorio),
  bairro: string().required(campoObrigatorio),
  clienteEmail: string()
    .email('Digite um e-mail válido')
    .required(campoObrigatorio),
});
