import { actionProps } from 'store/modules/types';
import { dataIndexProps } from './types';

interface stepProps {
  type: string;
}

export function setIndexStep(data: dataIndexProps): actionProps {
  return {
    type: '@step/SET_STEP',
    payload: {
      data,
    },
  };
}

export function nextStep(): stepProps {
  return {
    type: '@step/NEXT_STEP',
  };
}

export function backStep(): stepProps {
  return {
    type: '@step/BACK_STEP',
  };
}
