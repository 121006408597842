// eslint-disable-next-line no-shadow
export enum dataBiometriaTypes {
  SET_DATA_BIOMETRIA = '@user/SET_DATA_BIOMETRIA',
}

export interface dataBiometriaProps {
  clienteTelefone: string;
  clienteEmail: string;
  cpfCliente?: string;
  dataNascimento?: string;
  senha?: string;
}

export interface userDataBiometriaProps {
  data: dataBiometriaProps;
}

export const schemaBiometriaProps = {
  clienteTelefone: '',
  clienteEmail: '',
  cpfCliente: '',
  dataNascimento: '',
  senha: '',
};
