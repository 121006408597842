import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { FormHandles } from '@unform/core';

import { FormInput } from 'components/Form/Input';

import { formaPagamentoSelecionadaProps, protocoloProps } from 'utils/types';

import iconeEmail from 'assets/icons/icon-mail.svg';
import iconeTelefone from 'assets/icons/icon-phone.svg';
import iconUser from 'assets/icons/icon-user.svg';

import { ButtonBack } from 'components/ButtonBack';
import { ButtonNext } from 'components/ButtonNext';
import { Form } from '@unform/web';

import { useHistory } from 'react-router';

import { ButtonGroup } from 'components/Form/ButtonGroup';

import { useAuth } from 'hooks/auth';

import { ContentInfo } from 'components/ContentInfo';
import formValidator from 'pages/Pagamento/CartaoHavan/BiometriaCartaoHavan/formValidator';
import { getValidationsErros } from 'utils/getValidationsErros';
import { useDispatch, useSelector } from 'react-redux';
import { setDataBiometria } from 'store/modules/dataBiometria/action';
import { nextStep } from 'store/modules/dataIndexStep/action';
import { AplicationState } from 'store';
import {
  dataBiometriaProps,
  schemaBiometriaProps,
} from 'store/modules/dataBiometria/types';
import { Container } from './styles';

export const BiometriaCartaoHavan: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { getTotalSeconds } = useAuth();

  const dataBiometria = useSelector<AplicationState, dataBiometriaProps>(
    state => state.dataBiometria.data,
  );

  const selectPayment = JSON.parse(
    sessionStorage.getItem('@CheckoutWeb:formaPagamento') ?? '',
  ) as formaPagamentoSelecionadaProps;

  const protocoloPagamento = JSON.parse(
    sessionStorage.getItem('@CheckoutWeb:protocoloPagamento') ?? '',
  ) as protocoloProps;

  const history = useHistory();
  const dispatch = useDispatch();

  const clienteNome = formRef.current?.getFieldValue('clienteNome');
  const clienteTelefone = formRef.current?.getFieldValue('clienteTelefone');
  const clienteEmail = formRef.current?.getFieldValue('clienteEmail');

  const verificarDados = useMemo(
    () => !(clienteNome && clienteTelefone && clienteEmail),
    [clienteTelefone, clienteNome, clienteEmail],
  );

  const salvarDadosBiometria = useCallback(
    async data => {
      const dadosBiometria = {
        ...dataBiometria,
        clienteTelefone: data.clienteTelefone,
        clienteEmail: data.clienteEmail,
      };

      dispatch(setDataBiometria(dadosBiometria));
      dispatch(nextStep());
    },
    [dataBiometria, dispatch],
  );

  const handleSubmit = useCallback(
    async data => {
      formRef.current?.setErrors({});
      await formValidator
        .validate(data, {
          abortEarly: false,
        })
        .then(() => {
          salvarDadosBiometria(data);
        })
        .catch(err => {
          const errors = getValidationsErros(err);

          formRef.current?.setErrors(errors);
        });
    },
    [salvarDadosBiometria],
  );

  useEffect(() => {
    getTotalSeconds();
  }, [getTotalSeconds]);

  return (
    <Container
      typePayment={selectPayment.TipoPagamento}
      data-cy="biometriaCartaoHavan"
    >
      <ContentInfo />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className="mt-3 mb-3" id="contentTitlePayment">
          <p id="titleMethodPayment">Informe seus dados abaixo</p>
        </div>
        <FormInput
          disabled
          placeholder="Nome do cliente"
          label="clienteNome"
          icon={iconUser}
          defaultValue={protocoloPagamento.ClienteNome}
        />
        <FormInput
          width="input-group input-group-lg"
          mask="(99) 99999-9999"
          autoComplete="none"
          placeholder="Telefone"
          label="clienteTelefone"
          icon={iconeTelefone}
          defaultValue={dataBiometria.clienteTelefone}
        >
          *
        </FormInput>
        <FormInput
          width="input-group input-group-lg"
          autoComplete="none"
          placeholder="E-mail"
          label="clienteEmail"
          icon={iconeEmail}
          defaultValue={dataBiometria.clienteEmail}
        >
          *
        </FormInput>

        <div className="mt-5">
          <ButtonGroup>
            <ButtonBack
              title="VOLTAR"
              action={() => {
                dispatch(setDataBiometria(schemaBiometriaProps));
                history.goBack();
              }}
            />

            <ButtonNext
              title="PRÓXIMO"
              type="submit"
              disabled={verificarDados}
            />
          </ButtonGroup>
        </div>
      </Form>
    </Container>
  );
};
