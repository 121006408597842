import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  width: 580px;
  padding: 0 10px;

  @media screen and (max-width: 770px) {
    width: 100%;
  }
`;

export const ContentForm = styled.div`
  width: 100%;
`;

export const TitleSection = styled.p`
  color: ${colors.primary};
  margin: 32px 0 8px;
`;

export const ContentRow = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;

  @media screen and (max-width: 530px) {
    gap: 0;
    flex-direction: column;
  }
`;
