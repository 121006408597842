import React from 'react';
import Header from 'components/layout/Header';

import { Container, ContentDiv } from './styles';

const Content: React.FC = ({ children }) => {
  return (
    <Container>
      <Header />
      <ContentDiv>{children}</ContentDiv>
    </Container>
  );
};

export default Content;
