import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  justify-content: center;
  width: 100%;

  display: flex;
  padding: 0 24px;
  flex-direction: column;
  height: Calc(100vh - 400px);

  @media screen and (max-width: 530px) {
    height: Calc(100vh - 300px);
  }

  button {
    margin-top: 20px;

    width: 100%;
    height: 48px;

    background-color: #fff;
    border: 1px solid #076bee;
    box-sizing: border-box;
    border-radius: 8px;
    transition: all 0.2s;

    color: #076bee;
    font-weight: 700;

    :hover {
      background-color: ${shade(0.025, '#fff')};
    }
  }
`;
