import { object, string } from 'yup';

const campoObrigatorio = 'Campo obrigatório';

export default object().shape({
  clienteTelefone: string().min(15, 'Mínimo 11 digítos'),
  cep: string().min(9, 'Mínimo 8 digítos'),
  documentoTitularCartao: string().required(campoObrigatorio),
  telefoneTitularCartao: string().min(15, 'Mínimo 11 digítos'),
  emailTitularCartao: string()
    .required(campoObrigatorio)
    .email('Digite um e-mail válido'),
  logradouro: string().required(campoObrigatorio),
  numero: string().required(campoObrigatorio),
  bairro: string().required(campoObrigatorio),

  clienteEmail: string()
    .email('Digite um e-mail válido')
    .required(campoObrigatorio),
});
