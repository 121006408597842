import { useModal } from 'hooks/modal';
import { useEffect, useRef } from 'react';

// import { IconBaseProps } from "react-icons";

import { Container } from './styles';

// interface ModalProps {
//   titleChildren?: string;
//   modalExit?: boolean;
//   title?: string;
//   textInfo?: string;
//   icon?: React.ComponentType<IconBaseProps>;
// }

export const Modal: React.FC = () => {
  const { modalContent, Close } = useModal();
  const { title, content, footer, width } = modalContent;
  const btnShowModalRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (btnShowModalRef.current) {
      btnShowModalRef.current.click();
      const myModalEl = document.getElementById('modalHAVAN');
      if (myModalEl) {
        myModalEl.addEventListener('hidden.bs.modal', () => {
          Close();
        });
      }
    }
  }, [Close]);

  // if (children) {
  //   return (
  //     <div
  //       className="modal fade"
  //       id="staticBackdrop"
  //       data-bs-backdrop="static"
  //       data-bs-keyboard="false"
  //       tabIndex={-1}
  //       aria-labelledby="staticBackdropLabel"
  //       aria-hidden="true"
  //     >
  //       <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
  //         <div className="modal-content">
  //           <div className="modal-header">
  //             <h5 className="modal-title" id="staticBackdropLabel">
  //               {titleChildren}
  //             </h5>
  //             <button
  //               type="button"
  //               className="btn-close"
  //               data-bs-dismiss="modal"
  //               aria-label="Close"
  //             />
  //           </div>
  //           <div className="modal-body">{children}</div>
  //           <div className="modal-footer">
  //             <button
  //               type="button"
  //               className="btn btn-secondary"
  //               data-bs-dismiss="modal"
  //             >
  //               Fechar
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <>
      <div
        className="modal fade"
        id="modalHAVAN"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <Container
          className={`modal-dialog modal-dialog-centered modal-dialog-scrollable ${
            width ?? ''
          }`}
        >
          <div className="modal-content">
            <h4 className="modal-title mt-3">
              <b>{title}</b>
            </h4>
            <div className="modal-body">{content}</div>
            {footer && <div className="modal-footer">{footer}</div>}
          </div>
        </Container>
      </div>
      <button
        type="button"
        ref={btnShowModalRef}
        hidden
        data-bs-toggle="modal"
        data-bs-target="#modalHAVAN"
      >
        btn
      </button>
    </>
  );
};
