import React from 'react';

import { FormStepper } from 'components/FormWithSteps/FormStepper';
import { FormStep } from 'components/FormWithSteps/FormStep';

import { QrCodePix } from './QrCode';
import { Confirmacao } from './Confirmacao';
import { Container } from './styles';

export const Pix: React.FC = () => {
  const progressLabels = [
    'Escaneie ou copie a chave PIX',
    'Confirmação de pagamento',
  ];

  return (
    <Container>
      <FormStepper labels={progressLabels} noForm disabledButtonBack>
        <FormStep>
          <QrCodePix />
        </FormStep>
        <FormStep>
          <Confirmacao />
        </FormStep>
      </FormStepper>
    </Container>
  );
};
