import styled from 'styled-components';

interface containerProps {
  typePayment: number;
}

export const Container = styled.div<containerProps>`
  max-width: 850px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.2s;

  @media screen and (max-width: 500px) {
    box-shadow: none;
    margin-top: -20px;
  }
`;

export const DivRow = styled.div`
  transition: all 0.2s;
  gap: 8px;

  @media screen and (max-width: 400px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const DivRowContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  width: 100%;
  @media screen and (max-width: 830px) {
    flex-direction: column;
  }
`;

export const ContentLeft = styled.div`
  width: 100%;

  max-width: 600px;

  img {
    transition: all 0.2s;

    @media screen and (max-width: 300px) {
      display: none;
    }
  }

  .uppercaseInput {
    text-transform: uppercase;
    width: 100%;
    margin-left: 10px;

    ::placeholder {
      text-transform: initial;
    }
  }

  #contentTitlePayment {
    display: flex;
    align-items: center;
  }

  #titleMethodPayment {
    font-weight: bold;
    font-size: 18px;
    color: #06357a;
  }
`;

export const ContentRight = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 70px;

  transition: all 0.2s;
  align-items: center;

  @media screen and (max-width: 830px) {
    margin: 0;
  }
`;

export const ContentWeb = styled.div`
  @media screen and (max-width: 830px) {
    display: none;
  }
  margin-top: 20px;

  width: 100%;
`;

export const ContentMobile = styled.div`
  margin: 12px 0 20px;
  @media screen and (min-width: 830px) {
    display: none;
  }
`;
