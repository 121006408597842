import styled from 'styled-components';

type ButtonProps = {
  hasChildren?: boolean;
};

export const Container = styled.button<ButtonProps>`
  position: absolute;
  top: 5rem;
  right: 1.5rem;
  z-index: 1000;
  display: none;
`;
