import { ValidationError } from 'yup';

interface ErrosProps {
  [key: string]: string;
}

export function getValidationsErros(err: ValidationError): ErrosProps {
  const validationsErrors: ErrosProps = {};

  err.inner.forEach(error => {
    if (error.path) {
      validationsErrors[error.path] = error.message;
    }
  });

  return validationsErrors;
}
