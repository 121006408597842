import { Reducer } from 'redux';

import { userDataBiometriaProps, dataBiometriaTypes } from './types';

const INITIAL_STATE: userDataBiometriaProps = {
  data: {
    clienteTelefone: '',
    clienteEmail: '',
    cpfCliente: '',
    dataNascimento: '',
    senha: '',
  },
};

const dataPayment: Reducer<userDataBiometriaProps> = (
  state = INITIAL_STATE,
  action,
) => {
  if (action.type === dataBiometriaTypes.SET_DATA_BIOMETRIA) {
    return { ...state, data: action.payload.data };
  }

  return state;
};

export default dataPayment;
