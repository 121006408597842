import React from 'react';
import chipCard from 'assets/icons/chip-card.svg';

import { Card, DivRow, ContentValueCard } from './styles';

interface cartaoProps {
  numeroCartao: string;
  nomeCartao: string;
  validade: string;
  cvv: string;
}

const Cartao: React.FC<cartaoProps> = ({
  numeroCartao,
  nomeCartao,
  validade,
  cvv,
}) => {
  return (
    <Card>
      <ContentValueCard>
        <div>
          <p className="textCardInfo">Número do cartão</p>
          <p>{numeroCartao || '**** **** **** ****'}</p>
        </div>

        <img src={chipCard} alt="iconChip" />
      </ContentValueCard>

      <div>
        <p className="textCardInfo">Nome do titular</p>
        <p>
          {nomeCartao
            ? String(nomeCartao).toUpperCase()
            : 'NOME IMPRESSO NO CARTÃO'}
        </p>
      </div>

      <DivRow>
        <div className="validateCard">
          <p className="textCardInfo">Validade</p>
          <p>{validade || 'MM/AAAA'}</p>
        </div>
        <div className="contentCvv">
          <p className="textCardInfo">CVV</p>
          <p>{cvv || '***'}</p>
        </div>
      </DivRow>
    </Card>
  );
};

export default Cartao;
