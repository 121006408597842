import React, { useCallback, useState } from 'react';

import iconUser from 'assets/icons/icon-users.svg';

import { BiChevronDownCircle, BiChevronUpCircle } from 'react-icons/bi';

import { Container } from './styles';

interface infoProps {
  textoAlternativo?: string;
}

export const ContentInfo: React.FC<infoProps> = ({ textoAlternativo }) => {
  const [ocultarInfoStts, setOcultarInfoStts] = useState('');

  const ocultarInfoStatus =
    sessionStorage.getItem('@CheckoutWeb:ocultarInfo') ?? '';

  const ocultarInfo = useCallback(() => {
    const verificar = sessionStorage.getItem(ocultarInfoStatus) ?? 'true';

    if (verificar === 'false') {
      sessionStorage.setItem(ocultarInfoStatus, 'true');
    } else {
      sessionStorage.setItem(ocultarInfoStatus, 'false');
    }

    setOcultarInfoStts(verificar);
  }, [ocultarInfoStatus]);

  return (
    <Container>
      <div className="d-flex">
        <img src={iconUser} alt="iconUsers" className="me-2" />

        <p>
          <b>Verificação de identidade</b>
        </p>

        <button
          className="ms-auto"
          type="button"
          onClick={() => {
            ocultarInfo();
          }}
        >
          {ocultarInfoStts === 'true' ? (
            <BiChevronUpCircle />
          ) : (
            <BiChevronDownCircle />
          )}
        </button>
      </div>

      {ocultarInfoStts === 'true' && (
        <p>
          {textoAlternativo ||
            'Os dados abaixo são coletados para que possamos fazer uma verificação de identidade, trazendo mais segurança ao processo de pagamento'}
        </p>
      )}
    </Container>
  );
};
