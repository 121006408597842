import React from 'react';

import { AuthProvider } from './auth';
import { ModalProvider } from './modal';
import { SpinnerProvider } from './spinner';
import { TabProvider } from './tab';
import { ToastProvider } from './toast';
// import { InterceptorProvider } from "./interceptor";
import { FormProvider } from './form';
// import { PaginationProvider } from "./pagination";

const Hooks: React.FC = ({ children }) => (
  <ToastProvider>
    <AuthProvider>
      <SpinnerProvider>
        <ModalProvider>
          <TabProvider>
            <FormProvider>{children}</FormProvider>
          </TabProvider>
        </ModalProvider>
      </SpinnerProvider>
    </AuthProvider>
  </ToastProvider>
);

export default Hooks;
