import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from 'App';
import store from 'store';

import Hooks from './hooks';

ReactDOM.render(
  <Hooks>
    <Provider store={store}>
      <App />
    </Provider>
  </Hooks>,
  document.getElementById('root'),
);
